import React from "react";
import LandingTemplate from "@src/components/partials/landing/LandingTemplate";
import LandingForm from "@src/components/partials/landing/LandingForm";
import IntroducingImg from "./introducing.png";

export default function Introducing() {
  const props = {
    image: IntroducingImg,
    title: "Introducing ClassDojo for Districts.",
    body: "The new gold standard for K-12 districtwide communication. Simple, reliable, and private, with top-tier security and oversight. 100% free for district partners.",
    cta: "Get your district activity report",
    Form: (
      <LandingForm
        title="See how many K-12 schools in your district already use ClassDojo"
        submitLabel="Get your district activity report"
        name="introducton"
        version="1"
      />
    ),
  };

  return <LandingTemplate {...props} />;
}
